import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Studijní materiály Ekologie a životní prostředí" />
    <h1>Studijní materiály Ekologie a životní prostředí</h1>
    <p>Níže jsou sepsány on-line učební materiály z předmětu <strong>Z0025 Ekologie a životní prostředí</strong> na brněnské geografii na MUNI z podzimního semestru 2018. Jde o přepsané zápisky z přednášek a mohou se v nich tak objevovat chyby vlivem špatného pochopení látky, předmět bývá každý rok i mírně modifikován, takže na to je třeba dávat pozor.</p>
    <p><em>(Kdyby se Vám náhodou chtělo, můžete mě podpořit lajkem na <a href="https://www.facebook.com/zemepisec">Fejsbůku</a>)</em></p>
    <hr />
    <ImgCon><Eko /><div><h2>Co je ekologie? A co životní prostředí?</h2>
    <p>Podle zjednodušené definice je <strong>ekologie</strong> věda, která studuje <strong>vztahy organismů k okolí a k sobě navzájem</strong>. <strong>Životní prostředí</strong> je pak definováno jako <strong>vše, co vytváří přirozené podmínky existence organismů</strong>, včetně člověka. Za složky životního prostředí je považováno zejména ovzduší, voda, horniny, půda, organismy, ekosystémy a energie.</p>
    <p><em>(podrobněji v kapitole <Link to="/ekologie-zivotni-prostredi/uvod/">Úvod do studia ekologie a ŽP</Link>)</em></p></div></ImgCon>
    <hr />
    <h2>Témata studijních materiálů ekologie a životního prostředí</h2>
    <ul>
<li><Link to="/ekologie-zivotni-prostredi/uvod/">&Uacute;vod do studia ekologie a životn&iacute;ho prostřed&iacute;</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/">Životn&iacute; a vzrůstov&eacute; formy rostlin</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/zivotni-strategie/">Životn&iacute; a populačn&iacute; strategie druhů</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/chemicky-boj-alelopatie/">Chemick&yacute; boj (alelopatie) druhů</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/ph-reakce-prostredi/">pH reakce prostřed&iacute;</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/okyseleni-prostredi/">V&yacute;voj okyselen&iacute; prostřed&iacute; na &uacute;zem&iacute; ČR</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/nedostatek-nadbytek-zivin/">Nedostatek a nadbytek živin</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/dalsi-ekologicke-faktory/">Dal&scaron;&iacute; v&yacute;znamn&eacute; ekologick&eacute; faktory</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/historie-lesu/">Historie lesů na &uacute;zem&iacute; ČR</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/lesnicka-legislativa/">Lesnick&aacute; legislativa</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/klasifikace-lesu/">Klasifikace lesů</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/pojmy-lesnictvi/">Vybran&eacute; v&yacute;znamn&eacute; pojmy lesnictv&iacute;</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/prichod-a-vymirani-druhu/">Př&iacute;chod nov&yacute;ch a vym&iacute;r&aacute;n&iacute; původn&iacute;ch druhů</Link></li>
<li><Link to="/ekologie-zivotni-prostredi/repatriace-navrat-introdukce/">Repatriace, n&aacute;vrat původn&iacute;ch druhů a introdukce druhů lovn&yacute;ch</Link></li>
</ul>
  </Layout>
)
